import * as React from "react"
import Footer from "../components/Footer";
import Header from "../components/Header";

// markup
const IndexPage = () => {
  return (
    <main className="background">
      <Header pageName="index"/>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "75%", color: "white", fontFamily: "slkscr"}}>
        <p>EX NIHILO CREATIONS</p>
      </div>
      <Footer/>
    </main>
  );
}

export default IndexPage
